body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: #ebe134;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  gap: 5%;
  display: flex;
  font-size: xx-large;
  padding: 1%;
  justify-content: center;
}

.headerLink {
  padding: 0 1%;
}

footer {
  bottom: 0;
  right: 0;
  left: 0;
  padding-top: 10%;
}

a {
  text-decoration: underline;
  color: inherit;
}

h2 {
  padding-top: 5%;
}

.personalInfoDiv {
  display: flex;
}

.morePadding {
  padding-bottom: 3% !important;
}

.personalInf {
  text-align: left;
  margin: auto;
}

.HColor {
  color: #ebe134;
}

.course1 {
  background-color: #385a5c;
  width: 30vw;
  padding: 20px;
  border-radius: 12px;
  border: 2px solid #ccc;
  text-align: left;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  color: white;
  margin: 5vw 0 5vw 10vw;
}

.course1Mini {
  background-color: #385a5c;
  width: auto;
  height: 50%;
  padding: 20px;
  border-radius: 12px;
  border: 2px solid #ccc;
  text-align: left;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  color: white;
}

.course2 {
  align-self: flex-end;
  text-align: left;
  padding-right: 20%;
  background-color: #385a5c;
  width: 30vw;
  padding: 20px;
  border-radius: 12px;
  border: 2px solid #ccc;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  color: white;
  margin: 5vw 10vw 5vw 60vw;
}

.courseMiddle {
  align-self: flex-end;
  text-align: center;
  padding-right: 20%;
  background-color: #385a5c;
  width: auto;
  padding: 20px;
  border-radius: 12px;
  border: 2px solid #ccc;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  color: white;
  margin: 5vw 30vw;
}

.courseMiddle1 {
  align-self: flex-end;
  text-align: left;
  padding-right: 20%;
  background-color: #385a5c;
  width: 60vw;
  padding: 20px;
  border-radius: 12px;
  border: 2px solid #ccc;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  color: white;
  margin: auto;
  margin-top: 5vw;
}

.profileImg {
  border-radius: 20%;
  width: 20%;
  height: auto;
  margin: auto;
  position: relative;
  mask-image: radial-gradient(circle at center, black 50%, transparent 75%);
  -webkit-mask-image: radial-gradient(circle at center, black 50%, transparent 75%);
}

span {
  font-size: 70%;
}

/* Projects */
.reactImg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5%;
}

.nodeImg {
  padding-left: 10%;
  padding-top: 5%;
}

.nftT {
  padding: 7% 12%;
  max-height: 500px;
}

.web3 {
  max-height: 450px;
  padding: 3% 5%;
}

.nftMP {
  padding: 3% 5%;
  max-height: 500px;
}

/* About */
.aboutProfileIMG {
  width: 100%;
  height: auto;
  max-height: 800px;
  border-radius: 40%;
}

/* Animations */
@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.sunReal {
  width: 340px;
  animation: pulse 4s ease-in-out alternate infinite;
}

.sun {
  animation: App-logo-spin infinite 25s linear;
  width: 300px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.planet {
  width: 300px;
  animation: bounce 4s infinite;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0) translateX(0);
  }

  40% {
    transform: translateY(-10px) translateX(-10px);
  }

  60% {
    transform: translateY(-10px) translateX(10px);
  }
}

.tech {
  width: 200px;
}

/* Split Container */
.split-container {
  background-color: #385a5c;
  border: 2px solid #ccc;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  color: white;
  display: flex;
  width: 80%;
  max-width: 1200px;
  margin: 20px auto;
  margin-top: 5%;
  padding: 20px;
  border-radius: 12px;
  gap: 20px;
}

.split-text {
  width: 60%;
  text-align: left;
  padding: 15px;
}

.split-image {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  min-height: 300px;
}

.split-image-mutiple {
  width: 40%;
  display: flex-end;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.split-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: contain;
}

/* Media Queries */
@media (max-width: 1600px) {
  .courseMiddle1 {
    width: 80vw;
    padding-right: 10%;
  }
}

@media (max-width: 960px) {
  .aboutProfileIMG {
    width: 60%;
    margin: auto;
    border-radius: 20%;
  }

  .courseMiddle1 {
    display: flex;
    width: 90vw;
    padding-right: 5%;
    flex-wrap: wrap;
  }
}

@media (max-width: 760px) {

  .course1,
  .course1Mini,
  .course2,
  .courseMiddle {
    width: 90vw !important;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    flex-wrap: wrap;
  }

  .personalInfoDiv {
    flex-wrap: wrap;
  }

  .personalInf {
    width: 70%;
    margin: auto;
  }

  .profileImg {
    width: 70%;
    margin: auto;
    border-radius: 0;
    mask-image: linear-gradient(to right, 
      transparent 0%,
      black 15%,
      black 85%,
      transparent 100%
    );
    -webkit-mask-image: linear-gradient(to right, 
      transparent 0%,
      black 15%,
      black 85%,
      transparent 100%
    );
  }

  .reactImg {
    padding: 0;
  }

  .nodeImg {
    padding: auto !important;
    margin: auto !important;
  }

  .nftMP {
    width: 70%;
    padding: 0;
    max-height: none;
    height: auto;
  }

  .web3 {
    width: 90%;
    padding: 0;
    max-height: none;
    height: auto !important;
  }

  .nftT {
    width: 50%;
    padding: 0;
    max-height: none;
    height: auto !important;
  }

  .split-container {
    width: 86%;
    flex-direction: column;
  }

  .split-text,
  .split-image,
  .split-image-mutiple {
    width: 100% !important;
  }
}